export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/(site)": [10,[2],[3]],
		"/(site)/about-us": [11,[2],[3]],
		"/(site)/admin": [12,[2,4],[3]],
		"/(site)/admin/products": [13,[2,4],[3]],
		"/app": [32,[7]],
		"/app/configure": [33,[7]],
		"/app/dashboard": [34,[7,8]],
		"/app/dashboard/my-designs": [35,[7,8,9]],
		"/app/dashboard/my-shop": [36,[7,8]],
		"/app/dashboard/news": [37,[7,8]],
		"/app/dashboard/orders": [38,[7,8]],
		"/app/dashboard/settings": [39,[7,8]],
		"/app/login": [40,[7]],
		"/(site)/contact-us": [14,[2],[3]],
		"/(site)/cookie-policy-uk": [15,[2],[3]],
		"/(site)/copyright-disclaimer": [16,[2],[3]],
		"/(site)/delivery-information": [17,[2],[3]],
		"/(site)/design-manager": [18,[2],[3]],
		"/(site)/ethical-concerns": [19,[2],[3]],
		"/(site)/my-account/(authed)/my-images": [20,[2,5,6],[3]],
		"/(site)/my-account/(authed)/orders": [21,[2,5,6],[3]],
		"/(site)/payment-success": [22,[2],[3]],
		"/(site)/privacy-policy": [23,[2],[3]],
		"/(site)/return-policy": [24,[2],[3]],
		"/(site)/shop": [25,[2],[3]],
		"/(site)/shop/categories": [26,[2],[3]],
		"/(site)/shop/categories/[category]": [27,[2],[3]],
		"/(site)/shop/products": [28,[2],[3]],
		"/(site)/shop/products/[productId]": [29,[2],[3]],
		"/(site)/sign-in": [30,[2],[3]],
		"/(site)/terms-and-conditions": [31,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';